import React from "react"
import swal from 'sweetalert'

export default function Resposta({ children, success, onSuccess, onFailure }){

    const sucesso = () => {
        swal("Muito bem! Você acertou.", "Resposta correta", "success")
        onSuccess()
    }

    const erro = () => {
        swal("Hmm! Tente melhorar.", "Resposta errada", "error")
        onFailure()
    }
    return (
        <p className="m-3 p-3 resposta" onClick={ success ? sucesso : erro}>
            { children }
        </p>
    )
}