import React from 'react'

import background from '../../assets/img/background.png'

export default function(){
    return (
        <img src={background} className="img-fluid" style={{
            position: 'fixed', top: 0, maxWidth: '100%', zIndex: -999999999999
        }}></img>
    )
}