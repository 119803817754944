import React from 'react'

import Background from './components/Background'
import logo from './assets/logo.png'
import { useNavigate } from 'react-router-dom'

function Intro(){

    const navigate = useNavigate()

    const start = () => {
        navigate('/app')
    }
    return(
        <div className='container myContainer'>
            <div className="row">
                <div className="col pt-5">
                    <Background/>
                    <h1 className="text-center"><img src={logo} className='img-fluid' alt='feridas cronicas' /></h1>
                    <div className='row'>
                        <div className='col text-center'>
                            <h2>Sobre o jogo</h2>
                            <div className='mt-5'>
                                O jogo FERIDAS CRÔNICAS, visa demonstrar que é possível aprender  como avaliar, traçar as medidas preventivas e tratar as lesões cutâneas de forma lúdica. O aprendizado se dará da seguinte maneira: o usuário para recebe o prêmio (certificados) ou elogio, deverá passar por várias fases, as quais contam com vários desafios, em caso de errar o desafio, será dado um feedback de qual a alternativa correta, com isso o usuário reforçará o aprendizado sobre o tema ou aprenderá sobre cuidados com pacientes com feridas. Ao final do jogo, caso atinja 80% de aprendizado, será emitido pelo software um certificado.<br/><br/>
                                A utilização do jogo educativo no formato aplicativo, para utilização da avaliação, prevenção e tratamentos das lesões cutâneas tem como impacto social ofertar aos profissionais de saúde fundamentação teórica e prática, bem como padronização da avaliação, medidas preventivas, condutas terapêutica e orientações sobre o auto cuidado, que resulta em melhoria da assistência prestada aos individuo com lesões, cuidado individualizado, personalizado, sistematizado e com mínimo risco possível.

                                <button className='btn btn-success btn-lg mt-5' onClick={start}>Início</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    )
}

export default Intro