import React, { useState } from 'react'
import Background from './components/Background'

import personagem from './assets/img/personagem.png'
import Pergunta from './components/Pergunta'

import Resposta from './components/Resposta'

import questions from './questoes.json'

import logo from './assets/logo.png'
import { useNavigate } from 'react-router-dom'

function App() {
  const [data, setData] = useState({
    actuallyQuestion: 0,
    success: 0,
    failure: 0
  })

  const question = questions[data.actuallyQuestion]

  const onSuccess = () => setData({
    ...data,
    actuallyQuestion: data.actuallyQuestion + 1,
    success: data.success + 1
  })

  const onFailure = () => setData({
    ...data,
    actuallyQuestion: data.actuallyQuestion + 1,
    failure: data.failure + 1
  })

  const navigate = useNavigate()

  const start = () => navigate('/')

  if(data.actuallyQuestion === questions.length){
    return(
      <div className='container myContainer'>
        <div className="row">
          <div className="col pt-5">
            <Background/>
            <h1 className="text-center"><img src={logo} className='img-fluid' alt='feridas cronicas' /></h1>
            <div className="text-center">
              <img src={personagem} className="img-fluid w-75 mt-5" alt='Person'/>
            </div>
            <div className='row'>
              <div className='col text-center'>
                <h2>Resultado</h2>
                <div className='mt-5'>
                  <p>Você acertou { data.success } de { questions.length } questões.</p>
                  <p>Nota { (data.success / questions.length)  * 100}</p>

                  {
                    ((data.success / questions.length)  * 100) >= 80 ? (
                      <>
                        <form action="https://certificado-saude.progm.net.br/index.php" method='post' className='mt-4 mb-4'>
                          <div className='m-4'> 
                            <label>Digite seu nome para obter o certificado</label>
                            <input name='usuario' className='form-control' required/>
                            </div>
                            <button type='submit' className='btn btn-success'>Obter Certificado</button>
                        </form>
                      </>
                    ) : null
                  }

                  <button className='btn btn-success btn-lg' onClick={start}>Início</button>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    )
  }else{
    return (
      <div className="container myContainer">
        <div className="row">
          <div className="col pt-5">
            <Background/>
            <h1 className="text-center"><img src={logo} className='img-fluid' alt='feridas cronicas' /></h1>
            <div className="text-center">
              <img src={personagem} className="img-fluid w-75 mt-5" alt='Person'/>
            </div>
            <Pergunta >
              {question.title}
            </Pergunta>
  
            {
              question.items.map((item, i) => {
                return (
                  <Resposta key={i} success={ item.letter === question.answer } onSuccess={onSuccess} onFailure={onFailure}>
                    { item.letter } - { item.text }
                  </Resposta>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default App
