import React from 'react'

import Background from './components/Background'
import logo from './assets/logo.png'
import { useNavigate } from 'react-router-dom'

function Home(){

    const navigate = useNavigate()

    const start = () => {
        navigate('/intro')
    }
    return(
        <div className='container myContainer'>
            <div className="row">
            <div className="col pt-5">
                <Background/>
                <h1 className="text-center"><img src={logo} className='img-fluid' alt='feridas cronicas' /></h1>
                <div className='row'>
                    <div className='col text-center'>
                        <h2>Autores</h2>
                        <div className='mt-5'>
                            <p>Prof. Dr. Geraldo Magela Salomé</p>
                            <p className='mb-5'>Luana Gaudencio</p>

                            <h2 className='mt-5'>Financiamento</h2>

                            <p>
                                “Fundação de Amparo à Pesquisa do Estado de Minas Gerais – FAPEMIG” <br/>
                                DEMANDA UNIVERSAL “Edital/chamada: 001/2017”<br></br>
                                Número do processo: CDS-APQ-00235-17
                            </p>

                            <div className='row'>
                                <div className='col m-5 '>
                                    <a target="_blank" rel='noreferrer' href="https://www.univas.edu.br"><img src="https://www.univas.edu.br/img/univas.jpg" className='img-fluid' alt='Univás' /></a>
                                </div>
                            </div>

                            <button className='btn btn-success btn-lg mt-5' onClick={start}>Início</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>  
        </div>
    )
}

export default Home